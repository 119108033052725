import * as d3 from "d3";
import React, { useEffect, useRef } from "react";
import PlaceholderSVG from "./PlaceHolderSVG";

const LifeCycleChart = ({ data, tension }) => {
  const ref = useRef();

  useEffect(() => {
    if (!data || data.length === 0)
      return () => {
        <PlaceholderSVG />;
      };

    const svg = d3.select(ref.current);
    svg.selectAll("*").remove(); // Clear svg content before adding new elements

    const width = 1000;
    const height = 500;
    const margin = { top: 40, right: 30, bottom: 40, left: 150 }; // Increased left margin for color meter

    const x = d3
      .scaleLinear()
      .domain(d3.extent(data, (d) => d.year))
      .range([margin.left, width - margin.right]);

    const y = d3
      .scaleLinear()
      .domain([d3.min(data, (d) => d.value), d3.max(data, (d) => d.value)])
      .nice()
      .range([height - margin.bottom, margin.top]);

    const xAxis = (g) =>
      g.attr("transform", `translate(0,${height - margin.bottom})`).call(
        d3
          .axisBottom(x)
          .ticks(width / 80)
          .tickSizeOuter(0)
      );

    const yAxis = (g) =>
      g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y))
        .call((g) => g.select(".domain").remove());

    svg.append("g").call(xAxis);
    svg.append("g").call(yAxis);

    // Create a line generator with dynamic curve type based on tension
    const line = d3
      .line()
      .x((d) => x(d.year))
      .y((d) => y(d.value))
      .curve(getCurveType(tension)); // Function to determine curve type

    // Draw the entire path with the curve
    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 2)
      .attr("d", line);

    drawMarkersAndLabels(svg, data, x, y); // Function to draw markers and labels
    drawColorMeter(svg, width, height, margin, y); // Draw the color meter
    drawLegend(svg, width, height); // Draw the legend on the SVG
  }, [data, tension]); // Include tension in the dependency array to re-render when it changes

  return (
    <svg
      ref={ref}
      width="100%"
      viewBox="0 0 1000 500"
      preserveAspectRatio="xMidYMid meet"
    ></svg>
  );
};

function getCurveType(tension) {
  // Define thresholds and corresponding curve types
  if (tension < 0.1) return d3.curveStep; // Very sharp, step-like transitions
  if (tension < 0.2) return d3.curveStepBefore; // Step transitions starting before the point
  if (tension < 0.3) return d3.curveStepAfter; // Step transitions starting after the point
  if (tension < 0.4) return d3.curveLinear; // Straight lines without smoothing
  if (tension < 0.5) return d3.curveNatural; // Natural cubic splines, less smooth
  if (tension < 0.6) return d3.curveMonotoneX; // Preserves monotonicity in x
  if (tension < 0.7) return d3.curveCatmullRom.alpha(0.5); // Catmull-Rom spline with custom alpha
  if (tension < 0.8) return d3.curveCardinal.tension(0.5); // Cardinal spline, customizable tension
  if (tension < 0.9) return d3.curveCardinal; // Cardinal spline, default tension
  return d3.curveBasis; // Very smooth, using B-spline
}

function drawMarkersAndLabels(svg, data, xScale, yScale) {
  data.forEach((d) => {
    if (
      [100, -100, 80, -80, 10, -10, 60, -60, 20, -20, 0].includes(
        Math.abs(d.value)
      )
    ) {
      const labelYOffset = d.value > 0 ? -15 : 15; // Adjust label position based on value

      svg
        .append("circle")
        .attr("cx", xScale(d.year))
        .attr("cy", yScale(d.value))
        .attr("r", 5)
        .attr("fill", getColorForValue(d.value));

      svg
        .append("text")
        .attr("x", xScale(d.year))
        .attr("y", yScale(d.value) + labelYOffset) // Use dynamic offset
        .text(`${d.year}: ${d.value}`)
        .attr("text-anchor", "middle")
        .attr("font-size", "16px")
        .attr("fill", "black");
    }
  });
}

function getColorForValue(value) {
  if (value >= -100 && value < -80) return "darkred";
  if (value >= -80 && value < -60) return "brown";
  if (value >= -60 && value < -10) return "orange";
  if (value >= -10 && value < 0) return "blue";
  if (value >= 0 && value < 10) return "pink";
  if (value >= 10 && value < 60) return "gold";
  if (value >= 60 && value < 80) return "yellow";
  if (value >= 80 && value <= 100) return "darkgreen";
  return "black"; // Default color
}

function drawColorMeter(svg, width, height, margin, yScale) {
  const colorData = [
    { color: "darkgreen", range: [80, 100] },
    { color: "yellow", range: [60, 80] },
    { color: "gold", range: [10, 60] },
    { color: "pink", range: [0, 10] },
    { color: "blue", range: [-10, 0] },
    { color: "black", range: [-15, -10] },
    { color: "orange", range: [-60, -15] },
    { color: "brown", range: [-80, -60] },
    { color: "darkred", range: [-100, -80] },
  ];

  const meterWidth = 20;
  const meterX = margin.left - meterWidth - 60; // Add extra space between the graph and the color meter

  // Create a gradient for the color meter
  const gradient = svg
    .append("defs")
    .append("linearGradient")
    .attr("id", "color-gradient")
    .attr("x1", "0%")
    .attr("y1", "0%")
    .attr("x2", "0%")
    .attr("y2", "100%");

  colorData.forEach((d, i) => {
    const offsetStart = (i / colorData.length) * 100;
    const offsetEnd = ((i + 1) / colorData.length) * 100;

    gradient
      .append("stop")
      .attr("offset", `${offsetStart}%`)
      .attr("stop-color", d.color)
      .attr("stop-opacity", 1);

    gradient
      .append("stop")
      .attr("offset", `${offsetEnd}%`)
      .attr("stop-color", d.color)
      .attr("stop-opacity", 1);
  });

  // Draw the color meter
  svg
    .append("rect")
    .attr("x", meterX)
    .attr("y", margin.top)
    .attr("width", meterWidth)
    .attr("height", height - margin.top - margin.bottom)
    .attr("fill", "url(#color-gradient)");

  // Add labels to the color meter
  colorData.forEach((d, i) => {
    const yPos =
      margin.top +
      ((i + 0.5) / colorData.length) * (height - margin.top - margin.bottom);

    svg
      .append("text")
      .attr("x", meterX - 5)
      .attr("y", yPos)
      .attr("text-anchor", "end")
      .attr("font-size", "12px")
      .attr("fill", "black")
      .text(`${d.range[0]} to ${d.range[1]}`);
  });

  svg
    .append("text")
    .attr("x", meterX)
    .attr("y", margin.top - 10)
    .attr("text-anchor", "start")
    .attr("font-size", "12px")
    .attr("fill", "black");
}

function drawLegend(svg, width, height) {
  const legendData = [
    { color: "darkgreen", text: "Most" }, // 80 to 100
    { color: "yellow", text: "Lucky" }, // 60 to 80
    { color: "gold", text: "Less Lucky" }, // 10 to 60
    { color: "pink", text: "Neutral" }, // 0 to 10
    { color: "blue", text: "Neutral" }, // -10 to 0
    { color: "black", text: "Neutral" }, // -10 to 0
    { color: "orange", text: "Less Unlucky" }, // -60 to -10
    { color: "brown", text: "Unlucky" }, // -80 to -60
    { color: "darkred", text: "Most Unlucky" }, // -100 to -80
  ];

  const legend = svg
    .append("g")
    .attr("class", "legend")
    .attr("transform", `translate(${width - 150}, 30)`);

  legend
    .selectAll(null)
    .data(legendData)
    .enter()
    .append("rect")
    .attr("y", (d, i) => i * 25)
    .attr("width", 20)
    .attr("height", 20)
    .attr("fill", (d) => d.color);

  legend
    .selectAll(null)
    .data(legendData)
    .enter()
    .append("text")
    .attr("x", 30) // Offset text to the right of the color boxes
    .attr("y", (d, i) => i * 25 + 15) // Align text with boxes
    .text((d) => d.text)
    .attr("font-size", "12px")
    .attr("fill", "black");
}

export default LifeCycleChart;
