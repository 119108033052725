import React from "react";
import "./PlaceholderSVG.css"; // Importing the CSS for styles

const PlaceholderSVG = () => {
  return (
    <div className="placeholder-container">
      <svg
        width="100"
        height="100"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="placeholder-svg"
      >
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 6H13V13H11V6ZM11 17H13V15H11V17Z"
          fill="currentColor"
        />
      </svg>
      <span className="placeholder-text">
        Please select DOB to see the chart!
      </span>
    </div>
  );
};

export default PlaceholderSVG;
