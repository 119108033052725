import React, { useCallback, useEffect, useState } from "react";
import { processData } from "../utils/processData";
import LifeCycleChart from "./LifeCycleChart";
import PlaceholderSVG from "./PlaceHolderSVG";

const ScreenLifeCycleChart = () => {
  const [dob, setDob] = useState(() => {
    const storedDob = localStorage.getItem("dob");
    try {
      return storedDob
        ? JSON.parse(storedDob)
        : { year: "", month: "", day: "" };
    } catch (error) {
      console.error("Failed to parse dob from localStorage:", error);
      return { year: "", month: "", day: "" };
    }
  });
  const [data, setData] = useState([]);
  const [tension, setTension] = useState(0.8);
  const [yearRange, setYearRange] = useState({ start: 0, end: 12 });

  const updateData = useCallback(async () => {
    const dobString = `${dob.year}-${dob.month}-${dob.day}`;
    // Validate dobString format
    if (!dob.year || !dob.month || !dob.day) {
      console.error("Invalid date of birth:", dobString);
      return;
    }
    const processedData = await processData(dobString, yearRange);
    setData(processedData);
  }, [dob, yearRange]);

  useEffect(() => {
    if (dob.year && dob.month && dob.day) {
      updateData();
    }
  }, [dob, yearRange, updateData]);

  const handleDobChange = (e) => {
    const { name, value } = e.target;
    const newDob = { ...dob, [name]: value };
    setDob(newDob);
    try {
      localStorage.setItem("dob", JSON.stringify(newDob));
    } catch (error) {
      console.error("Failed to save dob in localStorage:", error);
    }
  };

  const handleTensionChange = (event) => {
    setTension(parseFloat(event.target.value));
  };

  const handleYearRangeChange = (event) => {
    const { name, value } = event.target;
    setYearRange((prev) => ({ ...prev, [name]: parseInt(value) }));
  };

  const handleNavigation = (direction) => {
    setYearRange((prev) => ({
      start: Math.max(0, prev.start + direction),
      end: Math.max(1, prev.end + direction),
    }));
  };

  const clearDob = () => {
    setDob({ year: "", month: "", day: "" });
    try {
      localStorage.removeItem("dob");
    } catch (error) {
      console.error("Failed to remove dob from localStorage:", error);
    }
  };

  const moveToNext = (current, nextFieldId) => {
    if (current.value.length === current.maxLength) {
      document.getElementById(nextFieldId).focus();
    }
  };

  return (
    <div className="font-cutive flex flex-col items-center min-h-screen bg-gray-50 p-4 md:p-8 rounded-lg shadow-lg mx-auto w-full max-w-4xl">
      <h1 className="text-4xl font-bold text-gray-800 mb-4">
        Future View Time Machine
      </h1>
      <p className="text-gray-700 text-lg mb-6">
        Find both chaotic and harmonic years across time. Use{" "}
        <a
          href="https://futureview.app"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800"
        >
          futureview.app
        </a>{" "}
        for precise dates.
      </p>
      <form
        onSubmit={(e) => e.preventDefault()}
        className="w-full max-w-4xl mx-auto space-y-4"
      >
        <div className="flex flex-col md:flex-row md:items-end md:space-x-4">
          <div className="flex-1">
            <label
              htmlFor="dob"
              className="block text-sm font-medium text-gray-700"
            >
              Enter your Date of Birth:
            </label>
            <div className="flex bg-white mt-2 gap-2">
              <div className="flex w-full border-2 rounded-md">
                <input
                  required
                  className="text-center placeholder:text-center px-2 py-2 rounded-l-md w-full"
                  type="number"
                  id="year"
                  name="year"
                  maxLength="4"
                  placeholder="YYYY"
                  onInput={(e) => moveToNext(e.target, "month")}
                  min="1"
                  max="9999"
                  value={dob.year}
                  onChange={handleDobChange}
                />
                <span className="py-2">/</span>
                <input
                  required
                  className="text-center placeholder:text-center py-2 w-full"
                  type="number"
                  id="month"
                  name="month"
                  maxLength="2"
                  placeholder="MM"
                  onInput={(e) => moveToNext(e.target, "day")}
                  min="1"
                  max="12"
                  value={dob.month}
                  onChange={handleDobChange}
                />
                <span className="py-2">/</span>
                <input
                  required
                  className="text-center placeholder:text-center px-2 py-2 rounded-r-md w-full"
                  type="number"
                  id="day"
                  name="day"
                  maxLength="2"
                  placeholder="DD"
                  min="1"
                  max="31"
                  value={dob.day}
                  onChange={handleDobChange}
                />
              </div>
              <button
                className="hover:scale-110 duration-500 group"
                type="button"
                onClick={clearDob}
              >
                <svg
                  className="w-5 h-5 fill-red-500 group-hover:fill-black duration-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"></path>
                </svg>
              </button>
            </div>
          </div>
          <div className="flex-1">
            <label
              htmlFor="tension"
              className="block text-sm font-medium text-gray-700"
            >
              Adjust different Chart types:
            </label>
            <input
              type="range"
              id="tension"
              min="0"
              max="1"
              step="0.05"
              value={tension}
              onChange={handleTensionChange}
              className="mt-1 block w-full"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Starting Age:
            </label>
            <input
              type="number"
              name="start"
              value={yearRange.start}
              onChange={handleYearRangeChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Ending Age:
            </label>
            <input
              type="number"
              name="end"
              value={yearRange.end}
              onChange={handleYearRangeChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500"
            />
          </div>
        </div>
        <div className="flex justify-center gap-4 mt-4">
          <button
            type="button"
            onClick={() => handleNavigation(-1)}
            className="px-4 py-2 bg-orange-600 hover:bg-red-800 text-white font-bold rounded-lg shadow"
          >
            Reverse
          </button>
          <button
            type="button"
            onClick={() => handleNavigation(1)}
            className="px-4 py-2 bg-orange-600 hover:bg-red-800 text-white font-bold rounded-lg shadow"
          >
            Fast Forward
          </button>
        </div>
      </form>

      <div className="mt-8 w-full">
        {!data || data.length === 0 ? (
          <PlaceholderSVG />
        ) : (
          <LifeCycleChart data={data} tension={tension} yearRange={yearRange} />
        )}
      </div>
      <div className="text-sm text-center text-gray-600 mt-4 md:hidden">
        If viewing on mobile, use LANDSCAPE MODE for better layout
      </div>
    </div>
  );
};

export default ScreenLifeCycleChart;
