export async function processData(dob, yearRange) {
  if (!dob) return [];
  const startYear = new Date(dob).getFullYear();
  const numYears = yearRange.end - yearRange.start + 1; // Ensure inclusive range
  const years = Array.from(
    { length: numYears },
    (_, i) => startYear + i + yearRange.start
  );

  const data = years.map((year) => {
    const yearIndex = (year - startYear) % 12; // Calculate year index within a 12-year cycle
    let value = 0; // Default neutral value

    // Set values based on the year index within the cycle
    switch (yearIndex) {
      case 0: // 1st year in the cycle
        value = -100;
        break;
      case 1: // 2nd year in the cycle
        value = 10;
        break;
      case 2: // 3rd year in the cycle
        value = 20;
        break;
      case 3: // 4th year in the cycle
        value = -60;
        break;
      case 4: // 5th year in the cycle
        value = 100;
        break;
      case 5: // 6th year in the cycle
        value = 0;
        break;
      case 6: // 7th year in the cycle
        value = -80;
        break;
      case 7: // 8th year in the cycle
        value = 0;
        break;
      case 8: // 9th year in the cycle
        value = 80;
        break;
      case 9: // 10th year in the cycle
        value = -60;
        break;
      case 10: // 11th year in the cycle
        value = 0;
        break;
      case 11: // 12th year in the cycle
        value = 10;
        break;
      default:
        value = 0; // Default neutral value
    }

    return { year, value };
  });

  return data;
}
