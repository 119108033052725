import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="bg-gray-800 p-4 shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-white text-lg font-bold hover:text-orange-400 transition duration-500 ease-in-out transform hover:scale-110">
          Future View Time Machine
        </div>
        <div className="flex space-x-4">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "text-orange-400 font-semibold transition duration-500 ease-in-out transform hover:scale-110"
                : "text-gray-300 hover:text-white transition duration-500 ease-in-out transform hover:scale-110"
            }
          >
            Home
          </NavLink>
          {/* <NavLink
            to="/emotions"
            className={({ isActive }) =>
              isActive
                ? "text-orange-400 font-semibold transition duration-500 ease-in-out transform hover:scale-110"
                : "text-gray-300 hover:text-white transition duration-500 ease-in-out transform hover:scale-110"
            }
          >
            Emotions
          </NavLink> */}

          <NavLink
            to="/feelings"
            className={({ isActive }) =>
              isActive
                ? "text-orange-400 font-semibold transition duration-500 ease-in-out transform hover:scale-110"
                : "text-gray-300 hover:text-white transition duration-500 ease-in-out transform hover:scale-110"
            }
          >
            Feelings
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
