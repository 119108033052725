import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Navbar from "./components/NavBar";
import PeriodGraph from "./components/PeriodGraph";
import ScreenLifeCycleChart from "./components/ScreenLifeCycleChart";

function App() {
  return (
    <Router>
      <Navbar />
      <div>
        <Routes>
          <Route path="/" element={<ScreenLifeCycleChart />} />
          {/* <Route path="/emotions" element={<ScreenLunarLifeCycleChart />} /> */}
          <Route path="/feelings" element={<PeriodGraph />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
