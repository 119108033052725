// utils/processPeriodData.js

export const periods = [
  { period_start: "3/20/2024", peak: "4/4/2024" },
  { period_start: "4/19/2024", peak: "5/5/2024" },
  { period_start: "5/20/2024", peak: "6/4/2024" },
  { period_start: "6/20/2024", peak: "7/5/2024" },
  { period_start: "7/21/2024", peak: "8/6/2024" },
  { period_start: "8/21/2024", peak: "9/6/2024" },
  { period_start: "9/22/2024", peak: "10/7/2024" },
  { period_start: "10/22/2024", peak: "11/6/2024" },
  { period_start: "11/21/2024", peak: "12/6/2024" },
  { period_start: "12/21/2024", peak: "1/4/2025" },
  { period_start: "1/19/2025", peak: "2/3/2025" },
  { period_start: "2/18/2025", peak: "3/5/2025" },
  { period_start: "3/20/2025", peak: "repeats" },
];

export const yValues = [-100, 10, 0, -60, 100, 10, -80, 0, 80, -60, 0, 10];

export function processPeriodData(birthDate) {
  const birthDateObj = new Date(birthDate);
  return periods.map((period, index) => {
    const yValue = yValues[index % yValues.length];
    const periodStartDate = new Date(birthDateObj);
    periodStartDate.setMonth(birthDateObj.getMonth() + index);
    return {
      period_start: periodStartDate,
      yValue,
    };
  });
}
