// components/PeriodGraph.js
import * as d3 from "d3";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { processPeriodData } from "../utils/processPeriodData";
import PlaceholderSVG from "./PlaceHolderSVG";

const PeriodGraph = () => {
  const ref = useRef();
  const [dob, setDob] = useState(() => {
    const storedDob = localStorage.getItem("dob");
    try {
      return storedDob
        ? JSON.parse(storedDob)
        : { year: "", month: "", day: "" };
    } catch (error) {
      console.error("Failed to parse dob from localStorage:", error);
      return { year: "", month: "", day: "" };
    }
  });
  const [data, setData] = useState([]);
  const [tension, setTension] = useState(0.8);

  const updateData = useCallback(() => {
    const dobString = `${dob.year}-${dob.month}-${dob.day}`;
    // Validate dobString format
    if (!dob.year || !dob.month || !dob.day) {
      console.error("Invalid date of birth:", dobString);
      return;
    }
    const processedData = processPeriodData(dobString);
    setData(processedData);
  }, [dob]);

  useEffect(() => {
    if (dob.year && dob.month && dob.day) {
      updateData();
    }
  }, [dob, updateData]);

  const handleDobChange = (e) => {
    const { name, value } = e.target;
    const newDob = { ...dob, [name]: value };
    setDob(newDob);
    try {
      localStorage.setItem("dob", JSON.stringify(newDob));
    } catch (error) {
      console.error("Failed to save dob in localStorage:", error);
    }
  };

  const clearDob = () => {
    setDob({ year: "", month: "", day: "" });
    try {
      localStorage.removeItem("dob");
    } catch (error) {
      console.error("Failed to remove dob from localStorage:", error);
    }
  };

  const moveToNext = (current, nextFieldId) => {
    if (current.value.length === current.maxLength) {
      document.getElementById(nextFieldId).focus();
    }
  };

  const handleTensionChange = (event) => {
    setTension(parseFloat(event.target.value));
  };

  useEffect(() => {
    const svg = d3.select(ref.current);
    svg.selectAll("*").remove(); // Clear svg content before adding new elements

    const width = window.innerWidth - 40; // Full width of the window minus some margin
    const height = 500;
    const margin = { top: 40, right: 30, bottom: 40, left: 120 }; // Increased left margin for color meter

    const x = d3
      .scaleTime()
      .domain(d3.extent(data, (d) => new Date(d.period_start)))
      .range([margin.left, width - margin.right]);

    const y = d3
      .scaleLinear()
      .domain([d3.min(data, (d) => d.yValue), d3.max(data, (d) => d.yValue)])
      .nice()
      .range([height - margin.bottom, margin.top]);

    const xAxis = (g) =>
      g.attr("transform", `translate(0,${height - margin.bottom})`).call(
        d3
          .axisBottom(x)
          .ticks(width / 80)
          .tickSizeOuter(0)
      );

    const yAxis = (g) =>
      g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y))
        .call((g) => g.select(".domain").remove());

    svg.append("g").call(xAxis);
    svg.append("g").call(yAxis);

    const line = d3
      .line()
      .x((d) => x(new Date(d.period_start)))
      .y((d) => y(d.yValue))
      .curve(getCurveType(tension));

    // Draw the entire path with the curve
    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 2)
      .attr("d", line);

    svg
      .selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d) => x(new Date(d.period_start)))
      .attr("cy", (d) => y(d.yValue))
      .attr("r", 5)
      .attr("fill", "red");

    // Add labels for the point values
    svg
      .selectAll(".point-label")
      .data(data)
      .enter()
      .append("text")
      .attr("class", "point-label")
      .attr("x", (d) => x(new Date(d.period_start)))
      .attr("y", (d) => y(d.yValue) - 20) // Adjust the y position to avoid overlap with the point
      .attr("text-anchor", "middle")
      .attr("font-size", "12px")
      .attr("fill", "black")
      .text(
        (d) =>
          `${new Date(d.period_start).getDate()} ${new Date(
            d.period_start
          ).toLocaleString("default", { month: "short" })},\n${new Date(
            d.period_start
          ).getFullYear()}\n: ${d.yValue}`
      );

    drawColorMeter(svg, width, height, margin, y); // Draw the color meter
    drawLegend(svg, width, height); // Draw the legend on the SVG
  }, [data, tension]);

  return (
    <div className="font-cutive flex flex-col items-center min-h-screen bg-gray-50 p-4 md:p-8 rounded-lg shadow-lg mx-auto w-full">
      <h1 className="text-4xl font-bold text-gray-800 mb-4">
        Future View Time Machine
      </h1>
      <p className="text-gray-700 text-lg mb-6">
        Find both chaotic and harmonic years across time. Use{" "}
        <a
          href="https://futureview.app"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800"
        >
          futureview.app
        </a>{" "}
        for precise dates.
      </p>
      <form
        onSubmit={(e) => e.preventDefault()}
        className="w-full max-w-4xl mx-auto space-y-4"
      >
        <div className="flex flex-col md:flex-row md:items-end md:space-x-4">
          <div className="flex-1">
            <label
              htmlFor="dob"
              className="block text-sm font-medium text-gray-700"
            >
              Enter your Date of Birth:
            </label>
            <div className="flex bg-white mt-2 gap-2">
              <div className="flex w-full border-2 rounded-md">
                <input
                  required
                  className="text-center placeholder:text-center px-2 py-2 rounded-l-md w-full"
                  type="number"
                  id="year"
                  name="year"
                  maxLength="4"
                  placeholder="YYYY"
                  onInput={(e) => moveToNext(e.target, "month")}
                  min="1"
                  max="9999"
                  value={dob.year}
                  onChange={handleDobChange}
                />
                <span className="py-2">/</span>
                <input
                  required
                  className="text-center placeholder:text-center py-2 w-full"
                  type="number"
                  id="month"
                  name="month"
                  maxLength="2"
                  placeholder="MM"
                  onInput={(e) => moveToNext(e.target, "day")}
                  min="1"
                  max="12"
                  value={dob.month}
                  onChange={handleDobChange}
                />
                <span className="py-2">/</span>
                <input
                  required
                  className="text-center placeholder:text-center px-2 py-2 rounded-r-md w-full"
                  type="number"
                  id="day"
                  name="day"
                  maxLength="2"
                  placeholder="DD"
                  min="1"
                  max="31"
                  value={dob.day}
                  onChange={handleDobChange}
                />
              </div>
              <button
                className="hover:scale-110 duration-500 group"
                type="button"
                onClick={clearDob}
              >
                <svg
                  className="w-5 h-5 fill-red-500 group-hover:fill-black duration-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"></path>
                </svg>
              </button>
            </div>
          </div>
          <div className="flex-1">
            <label
              htmlFor="tension"
              className="block text-sm font-medium text-gray-700"
            >
              Adjust different Chart types:
            </label>
            <input
              type="range"
              id="tension"
              min="0"
              max="1"
              step="0.05"
              value={tension}
              onChange={handleTensionChange}
              className="mt-1 block w-full"
            />
          </div>
        </div>
      </form>

      <div className="mt-8 w-full">
        {!data || data.length === 0 ? (
          <PlaceholderSVG />
        ) : (
          <svg
            ref={ref}
            width="100%"
            viewBox={`0 0 ${window.innerWidth} 500`}
            preserveAspectRatio="xMidYMid meet"
          ></svg>
        )}
      </div>
      <div className="text-sm text-center text-gray-600 mt-4 md:hidden">
        If viewing on mobile, use LANDSCAPE MODE for better layout
      </div>
    </div>
  );
};

function getCurveType(tension) {
  // Define thresholds and corresponding curve types
  if (tension < 0.1) return d3.curveStep; // Very sharp, step-like transitions
  if (tension < 0.2) return d3.curveStepBefore; // Step transitions starting before the point
  if (tension < 0.3) return d3.curveStepAfter; // Step transitions starting after the point
  if (tension < 0.4) return d3.curveLinear; // Straight lines without smoothing
  if (tension < 0.5) return d3.curveNatural; // Natural cubic splines, less smooth
  if (tension < 0.6) return d3.curveMonotoneX; // Preserves monotonicity in x
  if (tension < 0.7) return d3.curveCatmullRom.alpha(0.5); // Catmull-Rom spline with custom alpha
  if (tension < 0.8) return d3.curveCardinal.tension(0.5); // Cardinal spline, customizable tension
  if (tension < 0.9) return d3.curveCardinal; // Cardinal spline, default tension
  return d3.curveBasis; // Very smooth, using B-spline
}

function getColorForValue(value) {
  if (value >= -100 && value < -80) return "darkred";
  if (value >= -80 && value < -60) return "brown";
  if (value >= -60 && value < -10) return "orange";
  if (value >= -10 && value < 0) return "blue";
  if (value >= 0 && value < 10) return "pink";
  if (value >= 10 && value < 60) return "gold";
  if (value >= 60 && value < 80) return "yellow";
  if (value >= 80 && value <= 100) return "darkgreen";
  return "black"; // Default color
}

function drawColorMeter(svg, width, height, margin, yScale) {
  const colorData = [
    { color: "darkgreen", range: [80, 100] },
    { color: "yellow", range: [60, 80] },
    { color: "gold", range: [10, 60] },
    { color: "pink", range: [0, 10] },
    { color: "blue", range: [-10, 0] },
    { color: "orange", range: [-60, -10] },
    { color: "brown", range: [-80, -60] },
    { color: "darkred", range: [-100, -80] },
  ];

  const meterWidth = 20;
  const meterX = margin.left - meterWidth - 40;

  // Create a gradient for the color meter
  const gradient = svg
    .append("defs")
    .append("linearGradient")
    .attr("id", "color-gradient")
    .attr("x1", "0%")
    .attr("y1", "0%")
    .attr("x2", "0%")
    .attr("y2", "100%");

  colorData.forEach((d, i) => {
    const offsetStart = (i / colorData.length) * 100;
    const offsetEnd = ((i + 1) / colorData.length) * 100;

    gradient
      .append("stop")
      .attr("offset", `${offsetStart}%`)
      .attr("stop-color", d.color)
      .attr("stop-opacity", 1);

    gradient
      .append("stop")
      .attr("offset", `${offsetEnd}%`)
      .attr("stop-color", d.color)
      .attr("stop-opacity", 1);
  });

  // Draw the color meter
  svg
    .append("rect")
    .attr("x", meterX)
    .attr("y", margin.top)
    .attr("width", meterWidth)
    .attr("height", height - margin.top - margin.bottom)
    .attr("fill", "url(#color-gradient)");

  // Add labels to the color meter
  colorData.forEach((d, i) => {
    const yPos =
      margin.top +
      ((i + 0.5) / colorData.length) * (height - margin.top - margin.bottom);

    svg
      .append("text")
      .attr("x", meterX - 5)
      .attr("y", yPos)
      .attr("text-anchor", "end")
      .attr("font-size", "12px")
      .attr("fill", "black")
      .text(`${d.range[0]} to ${d.range[1]}`);
  });

  svg
    .append("text")
    .attr("x", meterX)
    .attr("y", margin.top - 10)
    .attr("text-anchor", "start")
    .attr("font-size", "12px")
    .attr("fill", "black");
}

function drawLegend(svg, width, height) {
  const legendData = [
    { color: "darkgreen", text: "Euphoria, ecstatic happiness" }, // 80 to 100
    { color: "yellow", text: "Joy, excitement" }, // 60 to 80
    { color: "gold", text: "Contentment, happiness" }, // 10 to 60
    { color: "pink", text: "Neutral, calm" }, // 0 to 10
    { color: "blue", text: "Sadness, melancholy" }, // -10 to 0
    { color: "orange", text: "Discomfort, frustration" }, // -60 to -10
    { color: "brown", text: "Severe depression, misery" }, // -80 to -60
    { color: "darkred", text: "Extreme despair, helplessness" }, // -100 to -80
  ];

  const legend = svg
    .append("g")
    .attr("class", "legend")
    .attr("transform", `translate(${width - 150}, 30)`);

  legend
    .selectAll(null)
    .data(legendData)
    .enter()
    .append("rect")
    .attr("y", (d, i) => i * 25)
    .attr("width", 20)
    .attr("height", 20)
    .attr("fill", (d) => d.color);

  legend
    .selectAll(null)
    .data(legendData)
    .enter()
    .append("text")
    .attr("x", 30) // Offset text to the right of the color boxes
    .attr("y", (d, i) => i * 25 + 15) // Align text with boxes
    .text((d) => d.text)
    .attr("font-size", "12px")
    .attr("fill", "black");
}

export default PeriodGraph;
